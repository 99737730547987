import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { exponentialRetry } from "../../common/utils";

export const getConductors = createAsyncThunk(
  "getConductors",
  async (
    { organizationId, tenantId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, isConductorsLoading } = getState().conductors;
    if (!isConductorsLoading || requestId !== currentRequestId) {
      return;
    }
    const url = `/organizations/${organizationId}/tenants/${tenantId}/conductors`;
    try {
      const response = await new HTTPClient({
        endpoint: url,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getConductorInstallationCommand = createAsyncThunk(
  "getConductorInstallationCommand",
  async (
    { organizationId, tenantId, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, generatingConductorCommand } =
      getState().conductors;
    if (!generatingConductorCommand || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await exponentialRetry(() =>
        new HTTPClient({
          endpoint: `/organizations/${organizationId}/tenants/${tenantId}/conductor`,
          method: HTTP_METHODS.POST,
          data: payload,
        }).callAuthorizedAPI(),
      );
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  error: null,
  isConductorInstalled: false,
  isConductorsLoading: false,
  conductors: {},
  errorGettingConductors: null,
  generatingConductorCommand: false,
  conductorError: false,
  beforeGeneratingCommand: true,
  conductorInstallCommand: null,
  vCenterConductors: {},
};

export const conductorsSlice = createSlice({
  name: "conductors",
  initialState,
  reducers: {
    reset: (state) => {
      state.error = null;
      state.isConductorInstalled = false;
      state.conductorInstallCommand = null;
      state.generatingConductorCommand = false;
      state.conductorError = false;
      state.beforeGeneratingCommand = true;
    },
    setConductorInstalled: (state, action) => {
      state.isConductorInstalled = action.payload;
    },
    clearConductors: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        state.refreshConductors = true;
        state.conductors = {};
      }
    },
    updateConductorInstalledStatus: (state, action) => {
      state.isConductorInstalled = action.payload.data ? true : false;
    },
  },
  extraReducers: {
    [getConductors.pending]: (state, action) => {
      state.isConductorsLoading = true;
      state.errorGettingConductors = null;
      state.currentRequestId = action.meta.requestId;
    },

    [getConductors.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.isConductorsLoading = false;
      state.errorGettingConductors = null;
      if (action.payload && action.payload.data) {
        _.set(
          state.conductors,
          `${action.payload.tenantId}`,
          action.payload.data?.conductors || [],
        );
        const conductors = action.payload.data?.conductors || [];
        const nonCloudConductors = conductors.filter(
          (conductor) =>
            conductor.useAzureFunction === false &&
            conductor.serverName !== undefined &&
            conductor.serverName !== null,
        );
        state.vCenterConductors = {
          ...state.vCenterConductors,
          [action.payload.tenantId]: nonCloudConductors,
        };
      }
    },

    [getConductors.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.errorGettingConductors = action.payload;
      state.isConductorsLoading = false;
    },
    [getConductorInstallationCommand.pending]: (state, action) => {
      state.beforeGeneratingCommand = false;
      state.generatingConductorCommand = true;
      state.error = null;
      state.conductorError = false;
      state.currentRequestId = action.meta.requestId;
    },
    [getConductorInstallationCommand.fulfilled]: (state, action) => {
      state.beforeGeneratingCommand = false;
      state.currentRequestId = null;
      state.generatingConductorCommand = false;
      state.conductorError = false;
      state.conductorInstallCommand = action.payload?.data?.command;
    },
    [getConductorInstallationCommand.rejected]: (state, action) => {
      state.beforeGeneratingCommand = false;
      state.currentRequestId = null;
      state.error = action.payload;
      state.conductorError = true;
      state.generatingConductorCommand = false;
    },
  },
});

export const {
  reset,
  clearConductors,
  updateConductorInstalledStatus,
  setConductorInstalled,
} = conductorsSlice.actions;

export default conductorsSlice.reducer;
