import { updateConductorInstalledStatus } from "../admin-actions/conductors/conductorsSlice.js";
import {
  clearImageSets,
  clearImageSetsSites,
} from "../admin-actions/images/imageSetSlice";
import { updateResourcePoolStatus } from "../admin-actions/pools/poolsSlice";
import {
  clearSites,
  updateSiteStatus,
} from "../admin-actions/sites/sitesSlice";
import { clearSpotGroups } from "../admin-actions/spotGroups/spotGroupsSlice";
import { modifyAlertNotification } from "../dashboard/slices/ciAlerts";
import {
  clearMachines,
  updateMachineStatus,
} from "../dashboard/slices/machineSlice";
import { updateLatestReleaseAnnouncements } from "../dashboard/slices/releaseAnnouncementsSlice";
import { updateUserSessionStatus } from "../dashboard/slices/userSessionsSlice";
import { updateTenantOnboardingStatus } from "../onboarding/onboardingSlice.js";
import { STATUS_UPDATE_SUBJECTS } from "./constants.js";

export const clearState = (type, tenantId, dispatch) => {
  switch (type) {
    case "ImageSet":
      dispatch(
        clearImageSets({
          tenantId: tenantId,
          silentRefresh:
            checkPath("/image-sets") ||
            checkPartialPath("/image-sets/detail/") ||
            checkPartialPath("/add-version") ||
            checkPath("/spot-group/action/create"),
        }),
      );
      break;
    case "SpotGroup":
      dispatch(
        clearSpotGroups({
          tenantId: tenantId,
          silentRefresh: checkPath("/spot-group"),
        }),
      );
      break;
    case "VirtualMachine":
      dispatch(
        clearMachines({
          tenantId: tenantId,
          silentRefresh: checkPath("/dashboard/machines"),
        }),
      );
      break;
    case "Sites":
      dispatch(clearSites({ tenantId: tenantId }));
      dispatch(clearImageSetsSites({ tenantId: tenantId }));
      break;
  }
};

const checkPath = (path) => {
  return window.location.pathname === path;
};

const checkPartialPath = (path) => {
  return window.location.pathname.indexOf(path) > -1;
};

export const broadCastReleases = (data, dispatch) => {
  dispatch(updateLatestReleaseAnnouncements({ data: data }));
};

export const broadCastTenantOnboardingStatus = (data, dispatch) => {
  dispatch(updateTenantOnboardingStatus({ data: data }));
};

export const broadCastTenantResourceStatus = (data, dispatch) => {
  var payload = JSON.parse(data);
  if (payload?.Subject === STATUS_UPDATE_SUBJECTS.MACHINE) {
    dispatch(updateMachineStatus({ data: payload }));
  } else if (payload?.Subject === STATUS_UPDATE_SUBJECTS.RESOURCE_POOL) {
    dispatch(updateResourcePoolStatus({ data: payload }));
  } else if (payload?.Subject === STATUS_UPDATE_SUBJECTS.SITE) {
    dispatch(updateSiteStatus({ data: payload }));
  } else if (payload?.Subject === STATUS_UPDATE_SUBJECTS.USER_SESSIONS) {
    dispatch(updateUserSessionStatus({ data: payload }));
  } else if (payload?.Subject === STATUS_UPDATE_SUBJECTS.CONDUCTOR_INSTALLED) {
    dispatch(updateConductorInstalledStatus({ data: payload }));
  }
};

export const broadcastAlerts = (data, dispatch) => {
  dispatch(
    modifyAlertNotification({
      data: {
        ...JSON.parse(data),
        updatedAt: new Date(),
      },
    }),
  );
};
