import { Box, styled } from "@mui/material";
import { Breadcrumbs } from "@veneer/core";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useCurrentMachine from "../hooks/useCurrentMachine";
import useCurrentPool from "../hooks/useCurrentPool";
import useCurrentSite from "../hooks/useCurrentSite";
import useCurrentTenant from "../hooks/useCurrentTenant";
import { generateBreadcrumb } from "../utils";

const ContainerBreadCrumb = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  "& li a": {
    color: theme.palette.foreground.light,
    padding: "4px 8px",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.foreground.medium,
      backgroundColor: theme.palette.neutral.backgroundLight,
      borderRadius: "50px",
    },
  },
  "& li span": {
    color: theme.palette.foreground.default,
    padding: "4px 8px",
    cursor: "default",
  },
}));

export default function BreadcrumbsWrapper(props) {
  const { route } = props;
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const currentTenant = useCurrentTenant();
  const currentMachine = useCurrentMachine();
  const currentPool = useCurrentPool();
  const currentSite = useCurrentSite();
  const currentGateway = location.state?.gatewayData;

  useEffect(() => {
    const crumbs = generateBreadcrumb(location.pathname);
    setBreadcrumbs(crumbs);
  }, [location, route]);

  const breadcrumbMap = useMemo(
    () => ({
      "<tenantName>": currentTenant?.displayName || "Overview",
      "<onboardingTitle>": currentTenant?.displayName || "Add tenant",
      "<machineName>": currentMachine?.name || null,
      "<poolName>": currentPool?.name || null,
      "<siteName>": currentSite?.name || null,
      "<gatewayName>": currentGateway?.clusterId || null,
    }),
    [
      currentGateway?.clusterId,
      currentMachine?.name,
      currentPool?.name,
      currentSite?.name,
      currentTenant?.displayName,
    ],
  );

  const crumbs = useMemo(
    () =>
      breadcrumbs
        .map((b, i) => {
          // replace the title with the actual names of elements: tenant, machine, site, etc...
          // Each element has a different location to get the name from.
          // This is the only place where it has to be done.

          let newText = breadcrumbMap[b.text] || b.text;

          const bc = {
            key: `bread-${i}`,
            text: newText,
          };

          // breadcrumb to be only a text, not a link
          const breadcrumbAsText = ["Settings"];

          // Only add a link if the breadcrumb has a destination URL or a URL
          const linkUrl = b.destinationUrl || b.url || null;
          if (linkUrl && !breadcrumbAsText.includes(b.text)) {
            bc.url = linkUrl;
            bc.customItem = <Link to={linkUrl}>{newText || ""}</Link>;
          } else {
            bc.customItem = <span>{newText || ""}</span>;
          }
          return bc;
        })
        // Remove empty title breadcrumbs
        .filter((b) => b.text !== "" && b.text !== null),
    [breadcrumbMap, breadcrumbs],
  );

  return (
    <ContainerBreadCrumb>
      <Breadcrumbs items={crumbs} responsiveBreakpoint="100px" />
    </ContainerBreadCrumb>
  );
}

BreadcrumbsWrapper.propTypes = {
  route: PropTypes.object,
};
