import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../../common/constants";
import HTTPClient from "../../../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getGateways = createAsyncThunk(
  "getGateways",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, gateways } = getState().gateways;

    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && gateways && gateways[selectedTenant]) {
      return { data: gateways[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/gateways`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createGateways = createAsyncThunk(
  "createGateways",
  async (
    { name, selectedTenant, organizationId, siteName, ip, port },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingCreateGateways } = getState().gateways;
    if (!loadingCreateGateways || requestId !== currentRequestId) {
      return;
    }
    const payload = {
      fqdn: ip,
      port: port,
      type: "edge",
      siteName,
      name,
    };

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/gateways`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteGateways = createAsyncThunk(
  "deleteGateways",
  async (
    { selectedTenant, organizationId, clusterId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingDeleteGateways } = getState().gateways;

    if (!loadingDeleteGateways || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/gateways/${clusterId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState = {
  gateways: {},
  loading: false,
  loadingCreateGateways: false,
  loadingDeleteGateways: false,
  error: null,
  isModalToDeleteOpen: false,
  calledTenants: [],
};

export const gatewaysSlice = createSlice({
  name: "gateways",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = null;
    },
    setIsModalToDeleteOpen: (state, action) => {
      state.isModalToDeleteOpen = action.payload;
    },
    setRemoveClusterID: (state, action) => {
      const originalGateways = state.gateways[action.payload?.selectedTenant];
      const removedGateways = originalGateways.filter(
        (gateway) => gateway.clusterId !== action.payload?.clusterId,
      );
      state.gateways[action.payload?.selectedTenant] = removedGateways;
    },
  },
  extraReducers: {
    [getGateways.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getGateways.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      const selectedTenant = action.payload?.selectedTenant;
      state.gateways[selectedTenant] = action.payload.data;
      if (!state.calledTenants.includes(selectedTenant)) {
        state.calledTenants = [...state.calledTenants, selectedTenant];
      }
    },
    [getGateways.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loading = false;
      const selectedTenant = action.payload?.selectedTenant;
      if (!state.calledTenants.includes(selectedTenant)) {
        state.calledTenants = [...state.calledTenants, selectedTenant];
      }
    },
    [createGateways.pending]: (state, action) => {
      state.loadingCreateGateways = true;
      state.currentRequestId = action.meta.requestId;
    },
    [createGateways.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingCreateGateways = false;
      state.gateways[action.payload?.selectedTenant] = [
        ...state.gateways[action.payload?.selectedTenant],
        action.payload.data.gateway,
      ];
    },
    [createGateways.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loadingCreateGateways = false;
    },
    [deleteGateways.pending]: (state, action) => {
      state.loadingDeleteGateways = true;
      state.currentRequestId = action.meta.requestId;
    },
    [deleteGateways.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loadingDeleteGateways = false;
    },
    [deleteGateways.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loadingDeleteGateways = false;
    },
  },
});

export const { resetErrors, setIsModalToDeleteOpen, setRemoveClusterID } =
  gatewaysSlice.actions;

export default gatewaysSlice.reducer;
