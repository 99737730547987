/* eslint-disable react/display-name */
import { useToast } from "@veneer/core";
import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_HOLDING_TIME,
  HYPERVISOR_TYPES,
  OPTIONS_PERSISTENT_VALUES,
  RESOURCE_POOL_TYPE,
} from "../../../../common/constants";
import { useOrgAndTenantId } from "../../../../common/hooks/useOrgAndTenantId";
import {
  statusMessages,
  validationHoldingTime,
} from "../../../../common/utils";
import {
  checkExistingPool,
  composeValidators,
  required,
  validateMaxVMWareMachineNameLength,
} from "../../../../common/validators";
import MachineNamingPatternForm from "../../../../onboarding/components/MachineNamingPatternForm";
import QuantityForm from "../../../../onboarding/components/QuantityForm";
import SelectAgentType from "../../../../onboarding/components/SelectAgentType";
import SelectTemplateForm from "../../../../onboarding/components/SelectTemplateForm";
import UserAssignmentPolicyForm from "../../../../onboarding/components/UserAssignmentPolicyForm";
import PoolNameForm from "../../../../onboarding/components/poolNameForm";
import { validatePoolName } from "../../../../onboarding/onboardingUtils";
import {
  createPool,
  setAddAgentType,
  setAddPoolName,
  setAssignmentPolicy,
  setAssignmentUser,
  setEntraGroupsVsphere,
  setHoldingTimeVsphere,
  setPassword,
  setPersistentVsphere,
  setPrefix,
  setProvisioningMethod,
  setQuantity,
  setSuffixLength,
  setSuffixNumberingPattern,
  setTargetUsersVsphere,
  setTemplate,
  setUsername,
} from "../../poolsSlice";
import {
  findLastTrueWithAllPreviousTrue,
  generateRandomNumber,
} from "../../poolsUtils";

//validates whether the form is enabled to move to the next step
export const useCreatePoolVsphereValidation = () => {
  const {
    name,
    template,
    agentType,
    quantity,
    prefix,
    suffixLength,
    suffixNumberingPattern,
    username,
    password,
    holdingTime,
    persistent,
  } = useSelector((state) => state.adminActions.pools.addPoolVsphere);

  const validUserAssignmentPolicy = {
    [OPTIONS_PERSISTENT_VALUES.manual]: true,
    [OPTIONS_PERSISTENT_VALUES.automated]: true,
    [OPTIONS_PERSISTENT_VALUES.floating]: validationHoldingTime(
      holdingTime.time,
      holdingTime.unit,
    ),
  };
  const { pools } = useSelector((state) => state.adminActions.pools);

  const { tenantId } = useOrgAndTenantId();
  const tenantPools = pools[tenantId] || [];

  const poolExists = Boolean(checkExistingPool(tenantPools)(name));

  const isNameValid =
    !composeValidators(required, validatePoolName)(name) && !poolExists;

  const isUsernameAndPasswordValid = !!(!!username && !!password);
  const isTemplateValid = !!(template && isUsernameAndPasswordValid);
  const isUserAssignmentPolicyValid =
    validUserAssignmentPolicy[persistent] || false;
  // TSW-218685 TODO: Uncomment when the backend is ready to receive these values
  // const isAssignmentPolicyValid = !!assignmentPolicy;
  // const isProvisioningMethodValid = !!provisioningMethod;
  const isAgentTypeValid = !!agentType;
  const isQuantityValid = !!(quantity && quantity > 0);
  const isVsphereMachineNameLengthValid = validateMaxVMWareMachineNameLength(
    prefix,
    suffixLength,
  );

  const isMachineNamePatterningValid = !!(
    prefix &&
    suffixLength &&
    suffixLength > 0 &&
    isVsphereMachineNameLengthValid &&
    suffixNumberingPattern
  );

  const validSteps = {
    isNameValid,
    isTemplateValid,
    // isProvisioningMethodValid,
    isAgentTypeValid,
    isQuantityValid,
    isMachineNamePatterningValid,
    isUserAssignmentPolicyValid,
  };

  validSteps.isValidToCreatePool = !!Object.values(validSteps).every(
    (i) => i === true,
  );

  return validSteps;
};

export const useVsphereStepContent = () => {
  const dispatch = useDispatch();
  const {
    name,
    template,
    // assignmentPolicy,
    // provisioningMethod,
    agentType,
    quantity,
    prefix,
    suffixLength,
    suffixNumberingPattern,
    username,
    password,
    persistent,
    entraGroups,
    targetUsers,
    holdingTime,
  } = useSelector((state) => state.adminActions.pools.addPoolVsphere);
  const { loadingTemplates, templates } = useSelector(
    (state) => state.adminActions.pools,
  );
  const deviceImageFormRef = useRef();

  //generates example machine paterning data based on selected options
  const dataCardsExamples = useMemo(() => {
    const resultGenerateCode =
      suffixNumberingPattern === "Sequential"
        ? Array.from({ length: 3 }, (_, index) =>
            (index + 1).toString().padStart(Number(suffixLength), "0"),
          )
        : Array.from({ length: 3 }, () => generateRandomNumber(suffixLength));
    const result = resultGenerateCode?.map((i) => `${prefix}${i}`);
    return (
      !!(prefix && suffixLength && suffixNumberingPattern) &&
      (result.toString() || "")
    );
  }, [prefix, suffixLength, suffixNumberingPattern]);

  const templateOptions = templates
    ?.map((i) => ({
      value: i?.templateName,
      label: i?.templateName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  //This function updates the redux state, it takes the data that the user is changing and which function should be executed
  const formUpdater = {
    poolNameForm: (data) => {
      dispatch(setAddPoolName(data?.name));
    },
    selectTemplateForm: (data) => {
      dispatch(setTemplate(data?.template));
      dispatch(setUsername(data?.username));
      dispatch(setPassword(data?.password));
    },
    assignmentPolicyForm: (data) => {
      dispatch(setAssignmentPolicy(data?.assignmentPolicy));
    },
    provisioningMethodForm: (data) => {
      dispatch(setProvisioningMethod(data?.provisioningMethod));
    },
    assignmentUsersForm: (data) => {
      dispatch(setAssignmentUser(data?.assignmentUser));
      dispatch(
        setQuantity(Object.keys(data?.assignmentUser).length.toString()),
      );
    },
    selectAgentType: (data) => {
      dispatch(setAddAgentType(data));
    },
    quantityForm: (data) => {
      dispatch(setQuantity(data?.quantity?.replace(/\D/g, "")));
    },
    machineNamingPatternForm: (data) => {
      dispatch(setPrefix(data?.prefix));
      dispatch(setSuffixLength(data?.suffixLength?.replace(/\D/g, "")));
      dispatch(setSuffixNumberingPattern(data?.suffixNumberingPattern));
    },
    userAssignmentPolicy: (data) => {
      dispatch(setHoldingTimeVsphere(data?.time || DEFAULT_HOLDING_TIME));
      dispatch(setEntraGroupsVsphere(data?.entraGroups || []));
      dispatch(setTargetUsersVsphere(data?.targetUsers || []));
      dispatch(setPersistentVsphere(data?.persistent || ""));
    },
  };

  const handleChange = (data, form) => {
    const updateForm = formUpdater[form];
    updateForm(data);
  };

  //Creation of components to be displayed in each step.
  return (stepNumber) => {
    switch (stepNumber) {
      case 0:
        return (
          <PoolNameForm onChange={handleChange} initialValues={{ name }} />
        );
      case 1:
        return (
          <SelectTemplateForm
            onChange={handleChange}
            initialValues={{
              template,
              username,
              password,
            }}
            options={templateOptions}
            loading={loadingTemplates}
          />
        );
      case 2:
        return (
          <UserAssignmentPolicyForm
            initialValues={{
              persistent,
              assignmentUser: targetUsers,
              entraGroups,
              time: holdingTime,
            }}
            onSubmit={(e) => handleChange(e, "userAssignmentPolicy")}
            ref={deviceImageFormRef}
            onChange={(e) => handleChange(e, "userAssignmentPolicy")}
            setFormValues={() => {}}
            description="Select appropriate user assignment policy to allocate users to the correct resources and roles."
            title="User assignment policy"
          />
        );
      // TSW-218685 TODO: Uncomment when the backend is ready to receive these values
      // case 2:
      //   return (
      //     <AssignmentPolicyForm
      //       initialValues={{ assignmentPolicy }}
      //       onChange={handleChange}
      //     />
      //   );
      // case 3:
      //   return (
      //     <ProvisioningMethodForm
      //       initialValues={{
      //         provisioningMethod,
      //       }}
      //       onChange={handleChange}
      //     />
      //   );
      case 3:
        return (
          <SelectAgentType
            initialValues={{ agentType }}
            onChange={(e) => handleChange(e, "selectAgentType")}
          />
        );
      case 4:
        return (
          <QuantityForm initialValues={{ quantity }} onChange={handleChange} />
        );
      case 5:
        return (
          <MachineNamingPatternForm
            initialValues={{ prefix, suffixLength, suffixNumberingPattern }}
            onChange={handleChange}
            dataCardsExamples={dataCardsExamples}
            formValues={{
              prefix,
              suffixLength,
            }}
          />
        );
      default:
        return null;
    }
  };
};

//data for creating the step, enabling the button to move to next step(based on the validator), configuring the header, and customizing the button label
export const useVsphereStepsMetaData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToast();

  const [stepErrors, setStepErrors] = React.useState({});

  const {
    name,
    template,
    username,
    password,
    agentType,
    quantity,
    prefix,
    suffixLength,
    suffixNumberingPattern,
    persistent,
    targetUsers,
    holdingTime,
  } = useSelector((state) => state.adminActions.pools.addPoolVsphere);

  const { selectedSite } = useSelector((state) => state.adminActions.pools);

  const { organizationId, tenantId } = useOrgAndTenantId();

  const validatorForm = useCreatePoolVsphereValidation();

  const handleCreatePool = async () => {
    const payload = {
      name: name,
      resourcePoolType: RESOURCE_POOL_TYPE.VDI,
      site: selectedSite?.label,
      deviceImage: template?.label,
      users: targetUsers,
      localUserName: username,
      localPassword: password,
      hypervisorType: HYPERVISOR_TYPES.VCENTER,
      assignmentType: persistent,
      targetServers: Number(quantity),
      serverNaming: {
        prefix: prefix,
        minNumOfDigits: suffixLength,
        numberingMethod: suffixNumberingPattern,
      },
      poolBehavior: "Persistent",
      pcoipAgentType: agentType,
    };

    if (persistent === OPTIONS_PERSISTENT_VALUES.floating) {
      payload.assignmentHoldingTime = holdingTime.time;
      payload.poolBehavior = persistent;
      payload.assignmentType = OPTIONS_PERSISTENT_VALUES.automated;
    }

    const resultAction = await dispatch(
      createPool({ organizationId, tenantId, payload }),
    );

    return resultAction;
  };

  const stepsMetadata = [
    {
      heading: "Enter pool name",
      canMoveToNextStep: validatorForm.isNameValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select template",
      canMoveToNextStep: validatorForm.isTemplateValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select user assignment policy",
      canMoveToNextStep: validatorForm.isUserAssignmentPolicyValid,
      onStepSubmit: async () => {},
    },
    // TSW-218685 TODO: Uncomment when the backend is ready to receive these values
    // {
    //   heading: "Select provisioning method",
    //   canMoveToNextStep: validatorForm.isProvisioningMethodValid,
    //   onStepSubmit: async () => {},
    // },
    {
      heading: "Select agent type",
      canMoveToNextStep: validatorForm.isAgentTypeValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select quantity",
      canMoveToNextStep: validatorForm.isQuantityValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Define machine naming pattern",
      stepSubmitButtonLabel: "Create pool",
      canMoveToNextStep:
        validatorForm.isMachineNamePatterningValid &&
        validatorForm.isValidToCreatePool,
      onStepSubmit: async () => {
        setStepErrors({});
        try {
          const { meta, payload, error } = await handleCreatePool();
          if (meta.requestStatus === "fulfilled") {
            setStepErrors((prevErrors) => ({ ...prevErrors, 4: false }));

            history.push("/dashboard/pools");

            return { error: false };
          }
          if (meta.requestStatus === "rejected") {
            setStepErrors((prevErrors) => ({ ...prevErrors, 4: true }));
            const messageError =
              payload?.detail ||
              error?.message ||
              statusMessages[payload?.status] ||
              "An error occurred while creating the pool.";
            addToast({
              text: messageError,
              type: "negative",
              timeout: 6,
              id: Date.now(),
            });
            return { error: true };
          }
          return { error: true };
        } catch (error) {
          addToast({
            text: `Failed to create pool: ${error}`,
            type: "negative",
            timeout: 6,
            id: Date.now(),
          });
          setStepErrors((prevErrors) => ({ ...prevErrors, 4: true }));
          return { error: true };
        }
      },
    },
  ];

  return {
    stepErrors,
    stepsMetadata,
  };
};

//identifies the most recent step if the user has already started filling out the form.
export const useCurrentStep = () => {
  const validatorForm = useCreatePoolVsphereValidation();
  const currentStep = useMemo(
    () => findLastTrueWithAllPreviousTrue(Object.values(validatorForm)),
    [],
  );
  return currentStep;
};
