import VerticalStepper from "@/common/components/VerticalStepper";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useCurrentTenant from "../../../../common/hooks/useCurrentTenant";
import { resetAddPoolAzure } from "../../poolsSlice";
import {
  useAzureGetStepContent,
  useAzureGetStepsMetaData,
  useCurrentStep,
} from "./addPoolAzureFormSettings";

export default function AddPoolAzure() {
  const currentTenant = useCurrentTenant();
  const selectedSiteLabel = useSelector(
    (state) => state.adminActions.pools?.selectedSite?.label,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { stepErrors, stepsMetadata } = useAzureGetStepsMetaData();
  const currentStep = useCurrentStep();

  useEffect(() => {
    if (!currentTenant?.id || !selectedSiteLabel) {
      history.push("/dashboard/pools");
    }
  }, [currentTenant?.id, history, selectedSiteLabel]);

  useEffect(() => {
    return () => dispatch(resetAddPoolAzure());
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <VerticalStepper
          stepsMetadata={stepsMetadata}
          getStepContent={useAzureGetStepContent()}
          showReset={false}
          lastSessionActiveStep={currentStep}
          confirmOnExit={false}
          defaultCancelPath="/dashboard/pools"
          stepErrors={stepErrors}
        />
      </Grid>
    </Grid>
  );
}
