import ProptTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../admin-actions/conductors/conductorsSlice";
import { TENANT_PROGRESS } from "../../common/constants";
import SideProgress from "./SideProgress";

const getVSphereExclusiveStep = (
  conductorError,
  beforeGeneratingCommand,
  generatingConductorCommand,
  lastStepStatus,
) => {
  const stepLabel = "Generating conductor command";
  let appendStatus = {};

  if (conductorError) {
    appendStatus = { step: stepLabel, status: TENANT_PROGRESS.FAILED };
  } else {
    if (beforeGeneratingCommand) {
      appendStatus = {
        step: stepLabel,
        status: TENANT_PROGRESS.NOT_STARTED,
      };
    } else if (generatingConductorCommand) {
      appendStatus = {
        step: stepLabel,
        status: TENANT_PROGRESS.IN_PROGRESS,
      };
    } else {
      appendStatus = { step: stepLabel, status: TENANT_PROGRESS.COMPLETED };
    }

    if (lastStepStatus === TENANT_PROGRESS.COMPLETED) {
      appendStatus.status = TENANT_PROGRESS.COMPLETED;
    }
  }

  return appendStatus;
};

const CurrentOnboardingSideProgress = (props) => {
  const dispatch = useDispatch();
  const { isVSphere } = props;

  const { tenant, tenantId, deploymentStatus, loadingDeploymentStatus } =
    useSelector((state) => state.onboarding);
  const {
    conductorError,
    beforeGeneratingCommand,
    generatingConductorCommand,
  } = useSelector((state) => state.conductors);
  const [stepsState, setStepsState] = useState([]);

  const steps = tenant[tenantId]?.deploymentStatus || deploymentStatus;

  useEffect(() => {
    dispatch(reset());
  }, []);

  // this section accounts for some VSphere tenant inconsistencies
  useEffect(() => {
    if (isVSphere && steps.length > 0) {
      const lastStepStatus = steps[steps.length - 1].status;

      const updatedSteps = [...steps];

      const vSphereStep = getVSphereExclusiveStep(
        conductorError,
        beforeGeneratingCommand,
        generatingConductorCommand,
        lastStepStatus,
      );

      updatedSteps.splice(updatedSteps.length - 1, 0, vSphereStep);

      setStepsState(updatedSteps);
    }
  }, [
    beforeGeneratingCommand,
    conductorError,
    generatingConductorCommand,
    steps,
    isVSphere,
  ]);

  return (
    <SideProgress
      data={isVSphere ? stepsState : steps}
      loading={loadingDeploymentStatus}
    />
  );
};

CurrentOnboardingSideProgress.propTypes = {
  isVSphere: ProptTypes.bool,
};

export default CurrentOnboardingSideProgress;
