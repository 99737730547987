import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import { Password } from "@veneer/core";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";
import CommonModal from "../../../common/components/CommonModal";
import TextInput from "../../../common/components/form/TextInput";
import VeneerButton from "../../../common/components/VeneerButton";

const CustomPassword = styled(Password)(({ theme }) => ({
  ["& label"]: {
    color: theme.palette.foreground.light,
  },
}));

const adCredentialsSchema = z.object({
  username: z.string().min(1, "Username is required"),
  password: z.string().min(1, "Password is required"),
});

const EditTenantAdCredentialsModal = ({
  isModalOpen,
  handleCloseModal,
  adCredentials,
  onConfirm,
}) => {
  const { loadingAdCredentials: loading } = useSelector(
    (state) => state.dashboard.tenant,
  );
  const { loadingADValidation } = useSelector((state) => state.onboarding);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      username: adCredentials || "",
      password: "",
    },
    resolver: zodResolver(adCredentialsSchema),
  });

  const onSubmit = (data) => {
    onConfirm(data);
  };

  return (
    <div>
      <CommonModal
        id="edit-tenant-modal"
        title="Update AD details"
        open={isModalOpen}
        onClose={handleCloseModal}
        primaryText="Save"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextInput
                  label="Username"
                  disabled={loading}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  {...field}
                />
              )}
            />
          </div>

          <div style={{ marginTop: "16px" }}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <CustomPassword
                  {...field}
                  label={"Password"}
                  separateLabel
                  data-testid="onboarding-vCenterUrl-password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <VeneerButton
              appearance="primary"
              type="submit"
              style={{ marginRight: "12px" }}
              disabled={loading || loadingADValidation || !isValid}
            >
              {loading ? "Saving..." : "Confirm"}
            </VeneerButton>
            <VeneerButton
              appearance="secondary"
              onClick={handleCloseModal}
              disabled={loading}
            >
              Cancel
            </VeneerButton>
          </div>
        </form>
      </CommonModal>
    </div>
  );
};

export default EditTenantAdCredentialsModal;
