import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getSitesList = createAsyncThunk(
  "getSitesList",
  async (
    { useCache = true, selectedTenant, organizationId, showDisabled = false },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingSites, sites } =
      getState().adminActions.sites;
    if (!loadingSites || requestId !== currentRequestId) {
      return;
    }
    if (useCache && sites && sites[selectedTenant]) {
      return { data: sites[selectedTenant], selectedTenant };
    }
    const showDisabledFlag = showDisabled
      ? "?showDisabled=" + showDisabled
      : "";
    const url =
      `/organizations/${organizationId}/tenants/${selectedTenant}/sites` +
      showDisabledFlag;
    try {
      const response = await new HTTPClient({
        endpoint: url,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAddressRange = createAsyncThunk(
  "getAddressRange",
  async (
    { selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAddressRange } =
      getState().adminActions.sites;
    if (!loadingAddressRange || requestId !== currentRequestId) {
      return;
    }
    const url = `/organizations/${organizationId}/tenants/${selectedTenant}/sites/networks`;
    try {
      const response = await new HTTPClient({
        endpoint: url,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editSites = createAsyncThunk(
  "editSites",
  async (
    { organizationId, tenantId, siteName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingEditSites, editSitesRequestId } =
      getState().adminActions.sites;
    if (!loadingEditSites || requestId !== editSitesRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/${siteName}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        tenantId,
        newSiteName: payload.name,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveSite = createAsyncThunk(
  "saveSite",
  async (
    { organizationId, tenantId, siteName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingAction } = getState().adminActions.sites;
    if (!loadingAction || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/create/${siteName}`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedPartner: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSitesSubscriptions = createAsyncThunk(
  "getSitesSubscriptions",
  async (
    { selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingSitesSubscriptions } =
      getState().adminActions.sites;
    if (!loadingSitesSubscriptions || requestId !== currentRequestId) {
      return;
    }

    const url = `/organizations/${organizationId}/tenants/${selectedTenant}/sites/subscriptions`;
    try {
      const response = await new HTTPClient({
        endpoint: url,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getLocationsList = createAsyncThunk(
  "getLocationsList",
  async ({ organizationId }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loadingLocations } =
      getState().adminActions.sites;
    if (!loadingLocations || requestId !== currentRequestId) {
      return;
    }

    const url = `/organizations/${organizationId}/locations`;
    try {
      const response = await new HTTPClient({
        endpoint: url,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSitesDirectoryTypes = createAsyncThunk(
  "getSitesDirectoryTypes",
  async (
    { tenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingDirectoryTypes } =
      getState().adminActions.sites;
    if (!loadingDirectoryTypes || requestId !== currentRequestId) {
      return;
    }

    const url = `/organizations/${organizationId}/tenants/${tenantId}/sites/sites/directoryTypes`;
    try {
      const response = await new HTTPClient({
        endpoint: url,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSites = createAsyncThunk(
  "deleteSites",
  async (
    { selectedOrganization, selectedTenant, siteDetails },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletingSite, deleteSiteRequestId } = getState().adminActions.sites;
    if (!deletingSite || requestId !== deleteSiteRequestId) {
      return;
    }

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}/sites/${siteDetails.name}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, siteDetails };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  error: null,
  createdSite: null,
  loadingAction: false,
  currentRequestId: null,
  loaderText: null,
  createStatus: null,
  loadingSites: null,
  sites: {},
  directoryTypes: {},
  errorLoadingSites: null,
  silentRefresh: null,
  refreshSites: null,
  loadingEditSites: null,
  editSitesRequestId: null,
  editSitesError: null,
  editSitesSuccessResponse: null,
  loadingAddressRange: null,
  errorLoadingAddressRange: null,
  addressRange: null,
  errorSitesSubscriptions: null,
  loadingSitesSubscriptions: null,
  errorLoadingLocations: null,
  loadingLocations: null,
  deleteStatus: null,
  deletingSite: null,
  deleteSiteError: null,
  deleteSiteRequestId: null,
  loadingDirectoryTypes: null,
  errorDirectoryTypes: null,
  selectedSite: {},
  isDeleteSiteDialogOpen: false,

  addSite: {
    site: {},
    siteName: "",
    azureRegion: null,
    vCenterUrl: "",
    vCenterUsername: "",
    vCenterPassword: "",
    joinToDomain: "",
    host: { label: "", name: "", value: "" },
    folder: "",
    selectedDataStores: [],
    vSphereResourcePool: "",
    conductorName: "",
  },
};

export const sitesSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.createSite = null;
      state.editSitesSuccessResponse = null;
      state.editSitesError = null;
      state.createStatus = null;
      state.loadingSites = null;
      state.errorLoadingSites = null;
      state.deleteStatus = null;
      state.deletingSite = null;
      state.deleteSiteError = null;
      state.deleteSiteRequestId = null;
      state.loadingAddressRange = null;
      state.errorLoadingAddressRange = null;
      state.loadingSitesSubscriptions = null;
      state.errorSitesSubscriptions = null;
      state.loadingLocations = null;
      state.errorLoadingLocations = null;
      state.loaderText = null;
      state.createdSite = null;
      state.loadingAction = null;
      state.error = null;
      state.loadingDirectoryTypes = null;
      state.errorDirectoryTypes = null;
      state.isDeleteSiteDialogOpen = false;
    },
    updateSiteStatus: (state, action) => {
      var payload = action.payload.data;
      var siteData = payload.Data;
      var tenantId = siteData.AnywareTenantId;

      state.sites[tenantId] = state.sites[tenantId]
        .filter(
          (site) =>
            !(
              site.name === siteData.SiteName &&
              siteData.SiteStatus === "Deleted"
            ),
        )
        .map((site) => {
          if (site.name === siteData.SiteName) {
            site.status = siteData.SiteStatus;
          }
          return site;
        });

      if (state.site && state.site.name === siteData.SiteName) {
        state.site.status = siteData.SiteStatus;
      }
    },
    clearSites: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        state.refreshSites = true;
        state.sites = {};
      }
    },
    setSiteDeleteStatus: (state, action) => {
      state.deleteStatus = action.payload;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    resetAddSite: (state) => {
      state.addSite = initialState.addSite;
    },
    setAddSite: (state, action) => {
      state.addSite = action.payload;
    },
    setSiteName: (state, action) => {
      state.addSite.siteName = action.payload.siteName;
    },
    setAzureRegion: (state, action) => {
      state.addSite.azureRegion = action.payload.azureRegion;
    },
    setConductorName: (state, action) => {
      state.addSite.conductorName = action.payload.conductorName;
    },
    setVCenterAccountDetails: (state, action) => {
      state.addSite.vCenterUrl = action.payload.vCenterUrl;
      state.addSite.vCenterUsername = action.payload.vCenterUsername;
      state.addSite.vCenterPassword = action.payload.vCenterPassword;
    },
    setDirectoryService: (state, action) => {
      state.addSite.joinToDomain = action.payload.joinToDomain;
    },
    setVCenterSiteDetails: (state, action) => {
      state.addSite.host = action.payload.host;
      state.addSite.folder = action.payload.folder;
      state.addSite.selectedDataStores = action.payload.selectedDataStores;
      state.addSite.vSphereResourcePool = action.payload.vSphereResourcePool;
    },
    openDialogToDeleteSite: (state) => {
      state.isDeleteSiteDialogOpen = true;
    },
    closeDialogToDeleteSite: (state) => {
      state.isDeleteSiteDialogOpen = false;
    },
  },
  extraReducers: {
    [getSitesList.pending]: (state, action) => {
      state.loadingSites = true;
      state.errorLoadingSites = null;
      state.currentRequestId = action.meta.requestId;
    },
    [getSitesList.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingSites = false;
      state.errorLoadingSites = null;
      if (action.payload.selectedTenant) {
        _.set(
          state.sites,
          `${[action.payload.selectedTenant]}`,
          action.payload && action.payload.data ? action.payload.data : [],
        );
      }
      state.refreshSites = null;
    },
    [getSitesList.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.errorLoadingSites = action.payload;
      state.loadingSites = false;
      state.refreshSites = null;
    },

    [getAddressRange.pending]: (state, action) => {
      state.loadingAddressRange = true;
      state.errorLoadingAddressRange = null;
      state.currentRequestId = action.meta.requestId;
    },
    [getAddressRange.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAddressRange = false;
      state.errorLoadingAddressRange = null;
      if (action.payload && action.payload.data) {
        state.addressRange = action.payload.data;
      }
    },
    [getAddressRange.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.errorLoadingAddressRange = action.payload;
      state.loadingAddressRange = false;
    },

    [getSitesSubscriptions.pending]: (state, action) => {
      state.loadingSitesSubscriptions = true;
      state.errorSitesSubscriptions = null;
      state.currentRequestId = action.meta.requestId;
    },
    [getSitesSubscriptions.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingSitesSubscriptions = false;
      state.errorSitesSubscriptions = null;
      if (action.payload && action.payload.data) {
        state.subscriptions = action.payload.data;
      }
    },
    [getSitesSubscriptions.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.errorSitesSubscriptions = action.payload;
      state.loadingSitesSubscriptions = false;
    },

    [getLocationsList.pending]: (state, action) => {
      state.loadingLocations = true;
      state.errorLoadingLocations = null;
      state.currentRequestId = action.meta.requestId;
    },
    [getLocationsList.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingLocations = false;
      state.errorLoadingLocations = null;
      if (action.payload && action.payload.data) {
        state.locations = action.payload.data;
      }
    },
    [getLocationsList.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.errorLoadingLocations = action.payload;
      state.loadingLocations = false;
    },

    [getSitesDirectoryTypes.pending]: (state, action) => {
      state.loadingDirectoryTypes = true;
      state.errorDirectoryTypes = null;
      state.currentRequestId = action.meta.requestId;
    },

    [getSitesDirectoryTypes.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingDirectoryTypes = false;
      state.errorDirectoryTypes = null;
      if (action.meta.arg.tenantId && action.payload && action.payload.data) {
        state.directoryTypes[action.meta.arg.tenantId] =
          action.payload.data.map((dir) => {
            return { name: dir };
          });
      }
    },

    [getSitesDirectoryTypes.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.errorDirectoryTypes = action.payload;
      state.loadingDirectoryTypes = false;
    },

    [saveSite.pending]: (state, action) => {
      state.loadingAction = true;
      state.createdSite = null;
      state.currentRequestId = action.meta.requestId;
      state.loaderText = "Saving Configuration";
      state.createStatus = null;
    },
    [saveSite.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;

      if (action.payload && action.payload.data) {
        _.remove(
          state.sites[action.payload.selectedPartner],
          (site) => site.name === action.payload.data.name,
        );
        state.sites[action.payload.selectedPartner] = [
          ...state.sites[action.payload.selectedPartner],
          action.payload.data,
        ];
      }

      state.createdSite = action.payload?.data;
      state.loaderText = null;
      state.createStatus = true;
      state.error = false;
    },
    [saveSite.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.loadingAction = false;
      state.createdSite = null;
      state.error = action.payload;
      state.loaderText = null;
      state.createStatus = null;
    },
    [editSites.pending]: (state, action) => {
      state.loadingEditSites = true;
      state.editSitesRequestId = action.meta.requestId;
      state.editSitesError = null;
    },
    [editSites.fulfilled]: (state, action) => {
      state.loadingEditSites = false;
      state.editSitesRequestId = null;
      state.editSitesError = false;
      state.editSitesSuccessResponse = action.payload?.data;
      if (action.payload?.tenantId) {
        const tenantId = action.payload.tenantId;
        const sites = current(state.sites);

        const newSites = _.map(sites[tenantId], (site) => {
          if (site.name === action.payload.siteName) {
            return { ...site, name: action.payload.newSiteName };
          }

          return site;
        });

        _.set(state.sites, [tenantId], newSites);
      }
    },
    [editSites.rejected]: (state, action) => {
      state.loadingEditSites = false;
      if (!action.payload) {
        state.editSitesError = { message: "Something unexpected happened." };
      }
      state.editSitesError = action.payload;
      state.editSitesRequestId = null;
      state.editSitesSuccessResponse = null;
    },
    [deleteSites.pending]: (state, action) => {
      state.deletingSite = true;
      state.deleteSiteRequestId = action.meta.requestId;
      state.deleteSiteError = null;
      state.deleteStatus = null;
    },
    [deleteSites.fulfilled]: (state) => {
      state.deletingSite = false;
      state.deleteSiteRequestId = null;
      state.deleteSiteError = "";
      state.deleteStatus = true;
    },
    [deleteSites.rejected]: (state, action) => {
      state.deletingSite = false;
      state.deleteSiteRequestId = null;
      state.deleteSiteError = action.payload;
      state.deleteStatus = false;
    },
  },
});

export const {
  resetErrors,
  clearSites,
  setSiteDeleteStatus,
  setSelectedSite,
  resetAddSite,
  setAddSite,
  setSiteName,
  setAzureRegion,
  setVCenterAccountDetails,
  setDirectoryService,
  setVCenterSiteDetails,
  setHost,
  setFolder,
  setSelectedDataStores,
  setVSphereResourcePool,
  updateSiteStatus,
  openDialogToDeleteSite,
  closeDialogToDeleteSite,
  setConductorName,
} = sitesSlice.actions;

export default sitesSlice.reducer;
