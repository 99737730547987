import { Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { Notification } from "../../common/components/Notification";
import { RadioGroupsBox } from "./RadioGroups";

export default function SelectAgentType({ title, onChange, initialValues }) {
  const theme = useTheme();
  const [toastVisibility, setToastVisibility] = useState(true);
  const options = [
    {
      value: "Standard",
      title: "Anyware standard agents",
      description:
        "For basic remote desktop capabilities for standard workloads.",
      isChecked: false,
    },
    {
      isChecked: false,
      value: "Graphics",
      title: "Anyware graphics agents",
      description:
        "For optimized for graphic-intensive workloads requiring high-fidelity visuals and GPU resources.",
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} color={theme.palette.foreground.light}>
        {title && (
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            {title}
          </Typography>
        )}
        <p>Select the appropriate agent type to match your workload needs.</p>
      </Grid>
      {toastVisibility && (
        <Grid item xs={12}>
          <Notification
            closeButton={true}
            title="After creating the pool, copy and run the enrollment commands from the pool's detail page to authorize your devices."
            type="informative"
            onClose={() => {
              setToastVisibility(false);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <RadioGroupsBox
          defaultValue={initialValues.agentType}
          options={options}
          onChange={(e) => {
            onChange(e?.find((item) => item.isChecked === true).value);
          }}
        />
      </Grid>
    </Grid>
  );
}

SelectAgentType.propTypes = {
  onChange: PropTypes.func,
};
