import { createApi } from "@reduxjs/toolkit/query/react";
import RTKbaseQuery from "../../../../common/servicers/RTKbaseQuery";

export const moveMachinesApi = createApi({
  reducerPath: "moveMachinesApi",
  baseQuery: RTKbaseQuery,
  endpoints: (builder) => ({
    moveMachines: builder.mutation({
      query: ({
        organizationId,
        tenantId,
        siteName,
        resourcePoolId,
        serverIds,
      }) => ({
        url: `/organizations/${organizationId}/tenants/${tenantId}/sites/${siteName}/moveVms`,
        method: "PUT",
        body: { resourcePoolId, serverIds },
      }),
    }),
  }),
});

export const { useMoveMachinesMutation } = moveMachinesApi;
