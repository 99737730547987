import { useSelector } from "react-redux";

const useCurrentPcoipSession = () => {
  const selectedSession = useSelector(
    (state) => state.dashboard?.pcoipSessionPerformance?.selectedSession,
  );
  return selectedSession || {};
};

export default useCurrentPcoipSession;
