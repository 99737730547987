import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import DialogWithLoader from "../../common/components/DialogWithLoader";
import VeneerButton from "../../common/components/VeneerButton";
import { useAuthContext } from "../../common/contexts/AuthContext";
import { REQ } from "../../common/utils";
import { sampleGetCall, setToken } from "../azureB2CSlice";
import { getAzureB2C } from "../factories/azure-ad-factory";
import { getB2CAccount, setB2CAccount } from "./../utils/azure-b2c";
import AzureADAuthProvider from "./azure-auth-provider";

function Login(props) {
  const dispatchStoreAction = useDispatch();
  const [account, setAccount] = useState(getB2CAccount());
  const [gettingAccessToken, setGetAccessToken] = useState(false);

  const { dispatch, state: authData } = useAuthContext();

  const handleSignIn = useCallback(() => {
    props
      .loginRedirect({
        ...REQ,
        scopes: ["openid", ...REQ.scopes],
        prompt: "select_account",
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error("An error occurred during login redirect:", e);
      });
  }, [props]);
  useEffect(() => {
    async function fetchData() {
      try {
        const resultAction = await dispatchStoreAction(sampleGetCall());
        unwrapResult(resultAction);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("An error occurred while fetching data:", err);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!authData.isAuthenticated) {
      handleSignIn();
    }
  }, [authData.isAuthenticated, handleSignIn]);
  const handleSignOut = () => {
    setB2CAccount(null);
    dispatch({ type: "unAuthenticate" });
    props.logout();
  };

  const handleGetAccessToken = () => {
    const { acquireTokenRedirect, acquireTokenSilent } = props;

    setGetAccessToken(true);
    acquireTokenSilent({ account, ...REQ, forceRefresh: false })
      .then((data) => {
        if (!data.accessToken) {
          if (authData.isAuthenticated) {
            dispatch({ type: "unAuthenticate" });
          }
          throw new InteractionRequiredAuthError();
        } else {
          if (!authData.isAuthenticated) {
            dispatch({ type: "authenticate" });
          }
          dispatchStoreAction(setToken(data.accessToken));
          alert(data.accessToken);
        }
      })
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          acquireTokenRedirect(REQ);
        }
        if (authData.isAuthenticated) {
          dispatch({ type: "unAuthenticate" });
        }
      })
      .finally(() => {
        setGetAccessToken(false);
      });
  };

  const handleRedirectSuccess = (data) => {
    if (!data) {
      return;
    }
    if (data.account) {
      if (!authData.isAuthenticated) {
        dispatch({ type: "authenticate" });
      }
      setB2CAccount(data.account);
      setAccount(data.account);
    }

    if (data.accessToken) {
      dispatchStoreAction(setToken(data.accessToken));
    }
  };

  props.setRedirectCallback(handleRedirectSuccess);

  if (props.noDisplay) {
    return (
      <DialogWithLoader
        open={true}
        title={"Please wait"}
        loaderText={"Loading your data.."}
      />
    );
  }
  if (authData.isAuthenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  }
  return (
    <div>
      {/* {!authData.isAuthenticated && (
        <Button variant="contained" color="primary" onClick={handleSignIn}>
          Sign In
        </Button>
      )} */}
      {gettingAccessToken && <span>...</span>}
      {authData.isAuthenticated && (
        <VeneerButton appearance="primary" onClick={handleGetAccessToken}>
          Get Access Token
        </VeneerButton>
      )}
      {authData.isAuthenticated && (
        <VeneerButton appearance="primary" onClick={handleSignOut}>
          logout
        </VeneerButton>
      )}
    </div>
  );
}

export default function AzureADAuthWrapper(props) {
  return (
    <AzureADAuthProvider
      client={getAzureB2C()}
      component={Login}
      componentProps={{ ...props }}
    />
  );
}
