import { IconCheckmarkCircle, IconInfo } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { TagIndicator } from "./TagIndicator";

const STATUS_MAP = {
  available: {
    type: "positive",
    label: "Available",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  unhealthy: {
    type: "neutral",
    label: "Unhealthy",
    icon: <IconInfo filled />,
  },
};

export const GatewaysTag = ({ status }) => {
  const { type, label, icon } = STATUS_MAP[status] || STATUS_MAP.unhealthy;

  return <TagIndicator label={label} type={type} leadingIcon={icon} />;
};

GatewaysTag.propTypes = {
  status: PropTypes.oneOf(["available", "unhealthy"]).isRequired,
};
