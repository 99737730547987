import { styled } from "@mui/material";

export const BadgeStyled = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "30px",
  padding: "7px 12px",
  margin: "10px",
  textTransform: "uppercase",
  boxShadow: "inset 0 -10px 10px rgba(0,0,0,0.3)",
  color: theme.palette.foreground.inverse,
  fontSize: "15px",
  display: "inline",
  zoom: "0.9",
}));
