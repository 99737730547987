import React from "react";

export const DELETE_WARNING_ERROR_MESSAGE =
  "Deleting a connection is a permanent and irreversible action that will cause the W365 Virtual Machine not to function.";

export const DELETE_DEVICE_WARNING_ERROR_MESSAGE =
  "Deleting a Device Image is a permanent and irreversible action that affect Virtual Machines.";

export const DELETE_IMAGE_SETS_WARNING_ERROR_MESSAGE =
  "Deleting this Image Set will eliminate the base image required for updating Spot Group instances.";

export const DELETE_API_ACCOUNT_WARNING_ERROR_MESSAGE =
  "Deleting an account is a permanent and irreversible action that will affect any external integrations using that API key/secret will no longer function.";

export const DELETE_BUSINESS_SERVER_WARNING_ERROR_MESSAGE =
  "Deleting a Business Server is a permanent and irreversible action that will result in data loss and services on this VM along users’ denied access.";

export const DELETE_SPOT_GROUPS_WARNING_ERROR_MESSAGE =
  "Deleting this Spot Group will delete all machines in the Spot Group, end users will no longer be able to access the desktops in this Spot Group.";

export const DELETE_SITES_WARNING_ERROR_MESSAGE =
  "Site once deleted cannot be recovered.";

export const DELETE_MONITORS_WARNING_ERROR_MESSAGE =
  "Deleting a Monitor is a permanent and irreversible action end users will no longer be able to access the respective Monitor.";

export const DELETE_NOTIFICATION_PROFILE_WARNING_ERROR_MESSAGE =
  "Deleting a Notification Profile is a permanent and irreversible action end users will no longer be able to access the respective Notification Profile.";

export const SPOT_GROUP_LOCATION_MESSAGE =
  "The Azure region of the Site selected above cannot store AVD metadata. Please select which Azure region to store AVD metadata in or select an alternate Site above.";

export const DELETE_ACTION_STEP_METADATA = [
  {
    heading: "Select Connection",
    hideAction: true,
  },
];

export const DESKTOP_ASSIGNMENT_TYPES = [
  { label: "Automatic", value: "Automatic" },
  { label: "Direct Assignment", value: "Direct" },
];

export const LICENSE_STATUS = {
  UNLICENSED: "Unlicensed",
};

export const DELETE_DEVICE_STEP_METADATA = [
  {
    heading: "Select Device Image",
    hideAction: true,
  },
];
export const DELETE_PROVISIONING_POLICY_STEP_METADATA = [
  {
    heading: "Select Provisioning Policy",
    hideAction: true,
  },
];
export const DELETE_API_ACCOUNTS_STEP_METADATA = [
  {
    heading: "Select Account",
    hideAction: true,
  },
];
export const DELETE_BUSINESS_SERVERS_STEP_METADATA = [
  {
    heading: "Select Business Server",
    hideAction: true,
  },
];

export const DELETE_MONITOR_STEP_METADATA = [
  {
    heading: "Select Monitor",
    hideAction: true,
  },
];

export const DELETE_CUSTOM_ACTION_STEP_METADATA = [
  {
    heading: "Select Custom Action",
    hideAction: true,
  },
];

export const DELETE_IMAGE_SETS_STEP_METADATA = [
  {
    heading: "Select Image Set",
    hideAction: true,
  },
];
export const DELETE_SPOT_GROUP_METADATA = [
  {
    heading: "Select Spot Group",
    hideAction: true,
  },
];

export const DELETE_SITES_STEP_METADATA = [
  {
    heading: "Select Site",
    hideAction: true,
  },
];

export const DELETE_NOTIFICATION_PROFILE_STEP_METADATA = [
  {
    heading: "Select Notification Profile",
    hideAction: true,
  },
];

export const DELETE_PROVISIONING_POLICY_WARNING_ERROR_MESSAGE =
  "Deleting a provisioning policy is a permanent and irreversible action that affect VM provisioning.";

export const DASHBOARD_ROUTE = "/dashboard/pools";

export const CONNECTION_LIST_ROUTE = "/windows365/connections";
export const DEVICE_IMAGE_LIST_ROUTE = "/windows365/device-images";
export const PROVISIONING_POLICY_LIST_ROUTE =
  "/windows365/provisioning-policies";
export const DEVICE_IMAGE_CREATE_ROUTE = "/windows365/device-images/create";
export const CONNECTIONS_CREATE_ROUTE =
  "/windows365/connections/create-connection";
export const CONNECTIONS_EDIT_ROUTE = "/windows365/connections/edit";
export const PROVISIONING_POLICTY_CREATE_ROUTE =
  "/windows365/provisioning-policies/create";
export const PROVISIONING_POLICTY_EDIT_ROUTE =
  "/windows365/provisioning-policies/edit";

export const SPOT_GROUP_LIST = "/spot-group";
export const EDIT_GROUP_LIST = "/spot-group/action/edit";
export const UPDATE_GROUP_LIST = "/spot-group/action/update";
export const ASSIGN_USER_SPOT_GROUP = "/spot-group/action/assignUsers";

export const CREATE_SPOT_GROUP_ROUTE = "/spot-group/action/create";
export const IMAGE_SET_LIST_ROUTE = "/image-sets";
export const IMAGE_SET_EDIT_ROUTE = "/image-sets/action/edit";

export const API_USERS_LIST_ROUTE = "/extensibility/accounts";
export const API_USERS_EDIT_ROUTE = "/extensibility/accounts/action/edit";

export const BUSINESS_SERVERS_LIST_ROUTE = "/business-servers";
export const BUSINESS_SERVERS_EDIT_ROUTE = "/business-servers/action/edit";

export const NOTIFICATION_PROFILES_LIST_ROUTE = "/notification-profiles";
export const NOTIFICATION_PROFILES_EDIT_ROUTE =
  "/notification-profiles/action/edit";

export const CUSTOM_ACTIONS_LIST_ROUTE = "/custom-actions";

export const SITES_LIST_ROUTE = "/sites";

export const MONITORS_LIST_ROUTE = "/monitors/userDefined";

export const CUSTOM_ACTION_LIST_ROUTE = "/custom-actions";

export const DISPLAY_NAME_MESSAGE =
  "The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores.";
export const DEVICE_IMAGE_VERSION_MESSAGE =
  "The image version name should follow Major(integer).Minor(integer).Patch(integer) format. For example: 0.0.1, 12.1.2";
export const NAME_LIMIT_LENGTH = {
  pool: {
    min: 2,
    max: 65,
  },
  site: {
    min: 2,
    max: 64,
  },
  tenant: {
    min: 2,
    max: 90,
  },
  conductor: {
    min: 2,
    max: 90,
  },
};

export const DISPLAY_NAME_MESSAGE_RESTRICTION = `Name should be ${NAME_LIMIT_LENGTH.tenant.min} - ${NAME_LIMIT_LENGTH.tenant.max} characters and only contain alphanumeric characters, space and dashes.`;
export const DISPLAY_POOL_NAME_MESSAGE_RESTRICTION = `Name should be ${NAME_LIMIT_LENGTH.pool.min} - ${NAME_LIMIT_LENGTH.pool.max} characters and only contain alphanumeric characters, space and dashes.`;
export const DISPLAY_SITE_NAME_MESSAGE_RESTRICTION = `Site name should be ${NAME_LIMIT_LENGTH.site.min} - ${NAME_LIMIT_LENGTH.site.max} characters and only contain alphanumeric characters and dashes.`;
export const DISPLAY_CONDUCTOR_NAME_MESSAGE_RESTRICTION = `Conductor name should be ${NAME_LIMIT_LENGTH.conductor.min} - ${NAME_LIMIT_LENGTH.conductor.max} characters and only contain alphanumeric characters, space and dashes.`;
export const SPOT_GROUP_LIST_ROUTE = "/spot-group";
export const SPOT_GROUP_STATUS = {
  LIMITED_AVAILABILITY: "Limited Availability",
};

export const SITE_CLOUDPC = "CloudPC";
export const SITE_SPOTGROUP = "SpotGroup";

export const SPOT_GROUP_FRIENDLY_NAME =
  "Spot Group Display Name for End Users.";
export const SPOT_GROUP_TAGS =
  "Tags are metadata elements that you apply to your Azure resources. They're key-value pairs that help you identify resources based on settings that are relevant to your organization.";
export const SPOT_GROUP_SELECT_SITE =
  "Azure Region in which we want to Deploy Spot Groups.";
export const SPOT_GROUP_ENTER_PROFILE_PATH =
  "Where the User Data will be stored. This is Data Volume.";
export const SPOT_GROUP_LICENSE =
  "Valid Spot PC License Type for creating Spot Groups (Pooled Desktops , Personal Desktops or GPU Desktops).";
export const SPOT_GROUP_SELECT_GROUPS =
  "Select Azure AD Group with users added to it based on the type of AD environment. This step is for giving access to users to Spot Group Resources.";
export const SPOT_GROUP_SELECT_IMAGESET =
  "Select valid Image Set and Image Version.VM images for Spot PC are contained within Image Sets. With this linkage intact, Spot PC optimization can automate the creation, deletion and availability of Spot PC session hosts for end users in real-time.";
export const SPOT_GROUP_DESCRIPTION =
  "Enter a useful description of this Spot Group to help you and other Spot PC Admins identify this Spot Group, who it is for, and how it is unique.";
export const SPOT_GROUP_REVIEW_AND_SAVE =
  "Review your selections, navigate to previous steps to make changes if any. Once saved, the Spot PC automation engine will build and configure the environment to support this new Spot Group, the Users and the Session Hosts Cloud Desktops.";

export const BUSINESS_SERVER_TAGS =
  "Tags are metadata elements that you apply to your Azure resources. They're key-value pairs that help you identify resources based on settings that are relevant to your organization.";
export const SELECT_BUSINESS_SERVER_LICENSE =
  "Valid Spot PC License Type for creating Business Server (Small, Medium or Large).";
export const BUSINESS_SERVER_SELECT_SOURCE_IMAGE =
  "The drop down for Image Source is populated from Azure Marketplace. Depending on your use case, select the appropriate version of Windows OS.";
export const REVIEW_AND_CREATE_BUSINESS_SERVER =
  "Review your selections, navigate to previous steps to make changes.";

export const UPDATE_METHODS = {
  OPPORTUNISTIC: "Opportunistic",
  IMMEDIATE: "Immediate",
  SCHEDULED: "Scheduled",
};
export const UPDATE_SPOT_GROUP_RELEASE_METHODS = [
  {
    name: UPDATE_METHODS.OPPORTUNISTIC,
    value: UPDATE_METHODS.OPPORTUNISTIC,
    description: () => (
      <>
        Host VMs with the old image version will continue to run to support
        current user sessions. All new user sessions will be routed to host VMs
        with the new image version. Active end users should experience no
        interruption and will gain access to the new image at their next login.
        Note: During this transition window (typically less than 1 day) users
        may be running on two different image versions and thus may not all have
        a standardized experience.
      </>
    ),
  },
  {
    name: UPDATE_METHODS.IMMEDIATE,
    value: UPDATE_METHODS.IMMEDIATE,
    description: () => (
      <>
        All users will be simultaneously switched to the new version of the
        image as soon as the new VMs are online (typically 15-20 minutes). New
        host VMs will be built and, once online, all new user sessions will be
        routed to the new host VMs. At the same time, active users will receive
        a notification that the new version is available and that they will be
        forced offline in five minutes.
      </>
    ),
  },
  {
    name: UPDATE_METHODS.SCHEDULED,
    value: UPDATE_METHODS.SCHEDULED,
    description: () => (
      <>
        All users will be simultaneously switched to the new version of the
        image at the scheduled time. The new host VMs will be built and online
        just prior to the scheduled time. At the schedule time, all new user
        sessions will be routed to the new host VMs. At the schedule time,
        active users will receive a notification that the new version is
        available and that they will be forced offline in five minutes.
      </>
    ),
  },
];

export const DESCRIPTION_MAX_LENGTH = 300;
export const NAME_MAX_LENGTH = 100;
export const BUSINESS_SERVER_NAME_MAX_LENGTH = 15;

export const STARTING_IMAGESET_VERSION = "0.0.0";
export const STARTING_IMAGESET_VERSION_FOR_GLOBAL = "0.0.1";

export const IMAGESET_SELECT_SITES =
  "Azure Region in which we want to create Image Version. You can select from all available sites in the Spot PC Tenant or a Global option. The Global option will add the image to the Azure Shared Image (Compute) Gallery and replicate the image across multiple Azure regions. NOTE: Due to replication time in all available sites, the global image set won't be immediately available for customization. Please expect approximately 20 minutes of delay once the global image is saved.";
export const IMAGE_SELECT_IMAGE_SOURCE =
  "The drop down for Image Source is populated from Azure Marketplace, with filters applied to narrow the list to images relevant to Spot PC. Depending on your use case, select the appropriate version of Windows 10 and 11.";
export const IMAGESET_IMAGE_NAME_AND_DESCRIPTION =
  "Enter a name and description for this Image Set. Choose the name and description that will help you find and organize Image sets.";
export const IMAGESET_ADD_NOTES_AND_SAVE =
  "Review your selections, navigate to previous steps to make changes. Add notes to document any valuable information about this image set and the version. The goal is to write down how and why changes are being made for reference later by you or your colleagues.";
export const IMAGESET_EDIT_REVIEW_AND_SAVE =
  "Review your selections, navigate to previous steps to make changes.";

export const SITE_STATUSES = {
  DeleteFailed: {
    displayValue: "Delete Failed",
  },
  CreateFailed: {
    displayValue: "Create Failed",
  },
  Error: {
    displayValue: "Error",
  },
  Creating: {
    displayValue: "Creating",
  },
  Deleting: {
    displayValue: "Deleting",
  },
  Available: {
    displayValue: "Available",
  },
};
