/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { queryString } from "../../common/utils";

const mockData = () => {
  const result = [];

  let value = 0;
  let time = new Date(2024, 12, 1, 0, 0, 0).getTime();

  for (let i = 0; i < 30; i++) {
    value = (Math.floor(Math.random() * 100) % 50) + 50;
    time += 1000 * 60 * 60 * 24;
    result.push({ value, time });
  }

  return result;
};

const data = {
  pcoip_agent_latency_milliseconds: [
    {
      metric: {
        name: "pcoip_agent_latency_milliseconds",
        labels: {
          deployment_id: "65141f4eb445db223c59d572",
          instance: "65564b6220a3123a6affec63",
          job: "pcoip_agent_statistics",
          machine_id: "65564b6220a3123a6affec63",
          pcoip_session_log_id: "0c285500-9bac-103d-b07a-000000000000",
        },
      },
      values: mockData(),
    },
  ],
  pcoip_agent_session_duration_seconds: [
    {
      metric: {
        name: "pcoip_agent_session_duration_seconds",
        labels: {
          deployment_id: "65141f4eb445db223c59d572",
          instance: "65564b6220a3123a6affec63",
          job: "pcoip_agent_statistics",
          machine_id: "65564b6220a3123a6affec63",
          pcoip_session_log_id: "0c285500-9bac-103d-b07a-000000000000",
        },
      },
      values: mockData(),
    },
  ],
  pcoip_agent_rx_packet_loss_percent: [
    {
      metric: {
        name: "pcoip_agent_rx_packet_loss_percent",
        labels: {
          deployment_id: "65141f4eb445db223c59d572",
          instance: "65564b6220a3123a6affec63",
          job: "pcoip_agent_statistics",
          machine_id: "65564b6220a3123a6affec63",
          pcoip_session_log_id: "0c285500-9bac-103d-b07a-000000000000",
        },
      },
      values: mockData(),
    },
  ],
  pcoip_agent_tx_packet_loss_percent: [
    {
      metric: {
        name: "pcoip_agent_tx_packet_loss_percent",
        labels: {
          deployment_id: "65141f4eb445db223c59d572",
          instance: "65564b6220a3123a6affec63",
          job: "pcoip_agent_statistics",
          machine_id: "65564b6220a3123a6affec63",
          pcoip_session_log_id: "0c285500-9bac-103d-b07a-000000000000",
        },
      },
      values: mockData(),
    },
  ],
};

export const getInstantQuery = createAsyncThunk(
  "getInstantQuery",
  async (
    { metric, pcoipSessionId, tenantId, organizationId },
    { getState, rejectWithValue },
  ) => {
    const params = {
      metric,
      pcoipSessionId,
    };

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/metrics/query?${queryString(params)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      const { data } = getState().dashboard.pcoipSessionPerformance;
      const metricState = data[tenantId]?.[pcoipSessionId][metric]; //getState().dashboard.pcoipSessionPerformance;

      return {
        data: response.data.data,
        // data: data[metric],
        tenantId,
        pcoipSessionId,
        metric,
        metricState,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getRangeQuery = createAsyncThunk(
  "getRangeQuery",
  async (
    { metric, pcoipSessionId, from, to, tenantId, organizationId },
    { getState, rejectWithValue },
  ) => {
    const { selectedSession } = getState().dashboard.pcoipSessionPerformance;

    if (!selectedSession?.pcoipSessionId) {
      return;
    }

    const params = {
      metric,
      pcoipSessionId,
      from,
      to,
    };

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/metrics/range?${queryString(params)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();

      return {
        data: response.data.data,
        // data: data[metric],
        tenantId,
        pcoipSessionId,
        metric,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  selectedSession: {},
  data: {},
  error: null,
  loadingMetrics: false,
};

export const pcoipSessionPerformanceSlice = createSlice({
  name: "pcoipSessionPerformance",
  initialState,
  reducers: {
    setSelectedSession: (state, action) => {
      state.selectedSession = action.payload;
    },
  },
  extraReducers: {
    [getInstantQuery.pending]: (state) => {
      state.error = null;
    },
    [getInstantQuery.fulfilled]: (state, action) => {
      const { data, tenantId, pcoipSessionId, metric, metricState } =
        action.payload;
      const metricState2 = { ...metricState };

      data.forEach((instant) => {
        const metricKey = JSON.stringify(instant.metric.labels);
        if (!metricState2?.[metricKey]) {
          return;
        }

        _.set(metricState2, `${metricKey}.latestValue`, instant.value.value);
        _.set(metricState2, `${metricKey}.values`, [
          ...metricState2[metricKey].values,
          [new Date(instant.value.time).getTime(), instant.value.value],
        ]);
      });

      _.set(
        state.data,
        `${tenantId}.${pcoipSessionId}.${metric}`,
        metricState2,
      );
      state.loadingMetrics = false;
    },
    [getRangeQuery.pending]: (state) => {
      state.loadingMetrics = true;
      state.error = null;
    },
    [getRangeQuery.fulfilled]: (state, action) => {
      const formatTimeseriesData = (payload) => {
        // Gets the common labels from all the series
        const commonLabels = Object.fromEntries(
          _.intersectionWith(
            ...payload.map((series) => Object.entries(series.metric.labels)),
            _.isEqual,
          ),
        );

        const resultMap = {};

        payload.forEach((series) => {
          resultMap[JSON.stringify(series.metric.labels)] = {
            // Removes the common labels from labels property and stringifies it
            name: Object.entries(
              _.omit(series.metric.labels, Object.keys(commonLabels)),
            )
              .map(([key, value]) => `${key}=${value}`)
              .join(", "),
            values: series.values.map(({ time, value }) => [
              new Date(time).getTime(),
              value,
            ]),
            latestValue: series.values[series.values.length - 1].value,
          };
        });

        return resultMap;
      };
      const { data, tenantId, pcoipSessionId, metric } = action.payload;
      state.loadingMetrics = false;
      const parsedData = formatTimeseriesData(data);
      _.set(state.data, `${tenantId}.${pcoipSessionId}.${metric}`, parsedData);
    },
    [getRangeQuery.rejected]: (state, action) => {
      state.error = action.payload || true;
      state.loadingMetrics = false;
    },
  },
});

export const { setSelectedSession } = pcoipSessionPerformanceSlice.actions;
export default pcoipSessionPerformanceSlice.reducer;
