import { combineReducers } from "@reduxjs/toolkit";
import {
  adminSlice,
  getIdentities,
  getIdentity,
  getInvitations,
} from "./adminSlice";
import { ciAlertsSlice, getCiAlerts } from "./ciAlerts";
import { logsSlice } from "./logSlice";
import { getMachine, getMachines, machineSlice } from "./machineSlice";
import { releaseAnnouncementsSlice } from "./releaseAnnouncementsSlice";
import { getAlerts, securitySlice } from "./securitySlice";
import { supportAdminSlice } from "./supportAdminSlice";
import { getTenantDetail, getTimeZones, tenantSlice } from "./tenantSlice";
import { getUserSessions, userSessionsSlice } from "./userSessionsSlice";
import { pcoipSessionPerformanceSlice } from "./pcoipSessionPerformanceSlice";

/*------Slices------*/
export const dashboardSlice = {
  actions: {
    ...machineSlice.actions,
    ...securitySlice.actions,
    ...userSessionsSlice.actions,
    ...adminSlice.actions,
    ...supportAdminSlice.actions,
    ...releaseAnnouncementsSlice.actions,
    ...tenantSlice.actions,
    ...logsSlice.actions,
    ...ciAlertsSlice.actions,
    ...pcoipSessionPerformanceSlice.actions,
  },
  reducer: combineReducers({
    machines: machineSlice.reducer,
    security: securitySlice.reducer,
    userSessions: userSessionsSlice.reducer,
    admin: adminSlice.reducer,
    supportAdmin: supportAdminSlice.reducer,
    releaseAnnouncements: releaseAnnouncementsSlice.reducer,
    tenant: tenantSlice.reducer,
    logs: logsSlice.reducer,
    ciAlerts: ciAlertsSlice.reducer,
    pcoipSessionPerformance: pcoipSessionPerformanceSlice.reducer,
  }),
};

/*------Actions------*/
export const { setMachines, setMachine, setUserSessions } =
  dashboardSlice.actions;

/*------API Actions------*/
export {
  getAlerts,
  getCiAlerts,
  getIdentities,
  getIdentity,
  getInvitations,
  getMachine,
  getMachines,
  getTenantDetail,
  getTimeZones,
  getUserSessions,
};

/*------Reducer------*/
export default dashboardSlice.reducer;
