import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../../../common/constants";
import RTKbaseQuery from "../../../common/servicers/RTKbaseQuery";

export const gatewaysApi = createApi({
  reducerPath: "gatewaysApi",
  baseQuery: RTKbaseQuery,
  endpoints: (builder) => ({
    getGateways: builder.query({
      query: ({ organizationId, selectedTenant }) => {
        return {
          url: `/organizations/${organizationId}/tenants/${selectedTenant}/gateways`,
          method: HTTP_METHODS.GET,
          isUnauthorizedCall: true,
        };
      },
    }),
  }),
});

export const { useGetGatewaysQuery } = gatewaysApi;
