import React from "react";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import { componentLoader } from "../../common/components/SuspenseComponent";
import { LAYOUTS } from "../../common/constants";

/*------Gateways Components------*/
const GatewaysList = React.lazy(() =>
  componentLoader(() => import("./pages/GatewaysList")),
);

const EditGatewaysContainer = React.lazy(() =>
  componentLoader(() => import("./pages/EditGatewaysContainer")),
);

// const CreateGatewaysContainer = React.lazy(() =>
//   componentLoader(() => import("./pages/CreateGatewaysContainer")),
// );

// const CreateWin365GatewayContainer = React.lazy(() =>
//   componentLoader(() => import("./pages/win365/CreateWin365Gateway")),
// );

// const CreateVMwareGatewayContainer = React.lazy(() =>
//   componentLoader(() => import("./pages/vmware/CreateVMwareGateway")),
// );

// const CreateRegisteredDevicesGatewayContainer = React.lazy(() =>
//   componentLoader(
//     () =>
//       import("./pages/vmRegisteredDevices/CreateRegisteredDevicesGateway"),
//   ),
// );

// const SelectServiceType = React.lazy(() =>
//   componentLoader(
//     () => import("../../onboarding/components/SelectServiceType"),
//   ),
// );

const GatewaysRoutes = [
  {
    path: "/tenant/gateways",
    authRequired: true,
    title: "Gateways",
    component: ParentRoute,
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Gateways" showQuickActions={true} />
    ),
    layoutStyle: LAYOUTS.MENU_SCREEN,
    highlightContent: true,
    routes: [
      {
        path: "/tenant/gateways",
        exact: true,
        title: "Gateways",
        component: () => (
          <ProtectedRouteSuspenseContainer component={GatewaysList} />
        ),
        permissionSet: [
          // PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      // {
      //   path: "/gateways/action/create/win365",
      //   exact: true,
      //   title: "Creating Windows 365 Cloud PC Gateway",
      //   component: () => (
      //     <ProtectedRouteSuspenseContainer
      //       component={CreateWin365GatewayContainer}
      //     />
      //   ),
      //   permissionSet: [
      //     PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.CREATE],
      //   ],
      // },
      // {
      //   path: "/gateways/action/create/vmware",
      //   exact: true,
      //   title: "Creating vSphere Gateway",
      //   component: () => (
      //     <ProtectedRouteSuspenseContainer
      //       component={CreateVMwareGatewayContainer}
      //     />
      //   ),
      //   permissionSet: [
      //     PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.CREATE],
      //   ],
      // },
      // {
      //   path: "/gateways/action/create/registeredDevices",
      //   exact: true,
      //   title: "Creating Registered Devices Gateway",
      //   component: () => (
      //     <ProtectedRouteSuspenseContainer
      //       component={CreateRegisteredDevicesGatewayContainer}
      //     />
      //   ),
      //   permissionSet: [
      //     PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.CREATE],
      //   ],
      // },
      // {
      //   path: "/gateways/action/retry/:gatewayName",
      //   exact: true,
      //   title: "Create",
      //   component: () => (
      //     <ProtectedRouteSuspenseContainer
      //       component={CreateGatewaysContainer}
      //       retry={true}
      //     />
      //   ),
      //   permissionSet: [
      //     PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.CREATE],
      //   ],
      // },
      {
        path: "/tenant/gateways/edit/:gatewayId?",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditGatewaysContainer} />
        ),
        permissionSet: [
          // PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.EDIT],
        ],
      },
      // {
      //   path: "/gateways/selectService/:tenantId?",
      //   title: "Select Service for Gateway",
      //   component: () => (
      //     <ProtectedRouteSuspenseContainer component={SelectServiceType} />
      //   ),
      //   permissionSet: [
      //     PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.EDIT],
      //   ],
      // },
    ],
  },
];

export default GatewaysRoutes;
