import { Grid, useTheme } from "@mui/material";
import { InlineNotification } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import CardExample from "../../common/components/CardExample";
import RadioButtons from "../../common/components/form/RadioButtons";
import TextInput from "../../common/components/form/TextInput";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { validateMaxVMWareMachineNameLength } from "../../common/validators";

const defaultOptions = [
  { value: "Sequential", label: "Sequential" },
  { value: "Random", label: "Random" },
];

const machineNamingSchema = z.object({
  prefix: z.string().min(1, "Prefix is required"),
  suffixLength: z
    .string()
    .min(1, "Suffix length is required")
    .refine((val) => Number(val) > 0, {
      message: "Suffix length must be greater than 0",
    })
    .transform((val) => Number(val)),
  suffixNumberingPattern: z.enum(["Sequential", "Random"]),
});

export default function MachineNamingPatternForm({
  onChange,
  dataCardsExamples,
  initialValues,
  form = "machineNamingPatternForm",
  options = defaultOptions,
}) {
  const theme = useTheme();

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: zodResolver(machineNamingSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  const formValues = watch();

  useEffect(() => {
    onChange(formValues, form);
  }, [formValues, onChange, form]);

  const isMachineNamingValid = validateMaxVMWareMachineNameLength(
    formValues.prefix,
    formValues.suffixLength,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} color={theme.palette.foreground.light}>
        Choose a static prefix for the Virtual Machines (VM) and a numbering
        suffix.
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <InlineNotification
            title="The combined length of the prefix and suffix must be 15 characters or less."
            closeButton={false}
          />

          <Controller
            name="prefix"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                label="Prefix"
                sx={{ width: "100%" }}
                error={!!error || !isMachineNamingValid}
                helperText={errors?.prefix?.message}
              />
            )}
          />

          <Controller
            name="suffixLength"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                type="number"
                label="Suffix Length"
                sx={{ width: "100%" }}
                error={!!error || !isMachineNamingValid}
                helperText={errors?.suffixLength?.message}
              />
            )}
          />

          {isMachineNamingValid ? null : (
            <InlineNotification
              title={
                "The combined length of the prefix and suffix must be 15 characters or less."
              }
              type="negative"
              closeButton={false}
            />
          )}

          <Controller
            name="suffixNumberingPattern"
            control={control}
            render={({ field }) => (
              <RadioButtons
                alignment="horizontal"
                options={options}
                label="Suffix numbering pattern"
                {...field}
              />
            )}
          />

          {dataCardsExamples && (
            <CardExample
              title="Sequential numbering example:"
              examples={dataCardsExamples}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
}

MachineNamingPatternForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  dataCardsExamples: PropTypes.bool,
  initialValues: PropTypes.object,
  form: PropTypes.string,
  options: PropTypes.array,
};
