import React from "react";
import {
  SuspenseLoader,
  componentLoader,
} from "./common/components/SuspenseComponent";
import { LAYOUTS, SUPPORT_ROLES } from "./common/constants";

import AlertNotificationRoute from "./common/components/AlertNotificationRoute";
import MyProfile from "./common/components/MyProfile";
import ParentRoute from "./common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "./common/components/ProtectedRoute";
import Redirection from "./common/components/Redirection";
import SideProgress from "./onboarding/components/SideProgress";

import BusinessServerRoutes from "./admin-actions/business-servers/routes";
import CustomActionsRoutes from "./admin-actions/custom-actions/routes";
import DataVolumesRoutes from "./admin-actions/data-volumes/routes";
import DeschutesRoutes from "./admin-actions/deschutes/routes";
import ImageSetRoutes from "./admin-actions/images/routes";
import NotificationProfilesRoutes from "./admin-actions/notificationProfiles/routes";
import { OrganizationsSwitch } from "./admin-actions/organizations-switch";
import SitesRoutes from "./admin-actions/sites/routes";
import SpotGroupRoutes from "./admin-actions/spotGroups/routes";
import AzureRoutes from "./azureB2C/routes";
import CostEstimatorRoutes from "./cost-estimator/routes";
import MonitorRoutes from "./dashboard/monitorRoutes";
import DashboardRoutes from "./dashboard/routes";
import ErrorNotFoundView from "./error-pages/ErrorNotFoundView";
import GatewaysRoutes from "./features/gateways/router";
import OnboardingRoutes from "./onboarding/routes";
import OrderSummaryRoutes from "./order-summary/routes";
import SettingsRoutes from "./settings/routes";

import LogoutRoute from "./Logout/routes";

const Home = React.lazy(() =>
  componentLoader(() => import("./common/components/Home")),
);
const RegistrationForm = React.lazy(() =>
  componentLoader(() => import("./registration/components/registrationForm")),
);
const AcceptInvitation = React.lazy(() =>
  componentLoader(
    () => import("./dashboard/components/admins/AcceptInvitation"),
  ),
);

const WelcomePage = React.lazy(() =>
  componentLoader(() => import("./registration/components/welcomePage")),
);

const AcceptSupportInvitation = React.lazy(() =>
  componentLoader(
    () => import("./dashboard/components/admins/AcceptSupportInvitation"),
  ),
);

const appRoutes = [
  ...LogoutRoute,
  ...AzureRoutes,
  // ...RegistrationRoutes,
  ...CostEstimatorRoutes,
  ...DeschutesRoutes,
  ...OnboardingRoutes,
  ...DashboardRoutes,
  ...ImageSetRoutes,
  ...SpotGroupRoutes,
  // ...APIAccountsRoutes,
  ...BusinessServerRoutes,
  ...OrderSummaryRoutes,
  ...SitesRoutes,
  ...GatewaysRoutes,
  ...DataVolumesRoutes,
  ...MonitorRoutes,
  ...NotificationProfilesRoutes,
  ...CustomActionsRoutes,
  ...SettingsRoutes,
  {
    path: "/accept-invitation",
    authRequired: true,
    component: ParentRoute,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        component: () => (
          <ProtectedRouteSuspenseContainer component={AcceptInvitation} />
        ),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/accept-support-invitation",
    authRequired: true,
    component: ParentRoute,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={AcceptSupportInvitation}
          />
        ),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/create-organization",
    authRequired: true,
    nobreadcrumbs: true,
    exact: true,
    component: ParentRoute,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/create-organization",
        component: () => (
          <ProtectedRouteSuspenseContainer component={WelcomePage} />
        ),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/create-organization/form",
    authRequired: true,
    nobreadcrumbs: true,
    exact: true,
    component: ParentRoute,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/create-organization/form",
        component: () => (
          <ProtectedRouteSuspenseContainer component={RegistrationForm} />
        ),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/organizations/:orgId/tenants/:tenantId/dashboard/alerts",
    authRequired: true,
    component: ParentRoute,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/organizations/:orgId/tenants/:tenantId/dashboard/alerts",
        component: () => <AlertNotificationRoute />,
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/organizations/:orgId",
    authRequired: true,
    nobreadcrumbs: true,
    component: ParentRoute,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/organizations/:orgId",
        component: () => <OrganizationsSwitch />,
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/",
    component: ParentRoute,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/redirect",
        component: () => <Redirection />,
        layoutStyle: LAYOUTS.MENU_SCREEN,
      },
      {
        path: "/my-profile",
        component: () => <MyProfile />,
        roles: [...SUPPORT_ROLES],
        layoutStyle: LAYOUTS.MENU_SCREEN,
      },
      {
        path: "/progress",
        component: () => <SideProgress />,
        roles: [...SUPPORT_ROLES],
        layoutStyle: LAYOUTS.MENU_SCREEN,
      },
      {
        exact: true,
        path: "/",
        component: () => SuspenseLoader(() => <Home />),
        roles: [...SUPPORT_ROLES],
        layoutStyle: LAYOUTS.MENU_SCREEN,
      },
      {
        path: "*",
        component: () => <ErrorNotFoundView />,
        layoutStyle: LAYOUTS.FULL_SCREEN,
      },
    ],
  },
];

export default appRoutes;
