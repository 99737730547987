import { createApi } from "@reduxjs/toolkit/query/react";
import RTKbaseQuery from "../../../common/servicers/RTKbaseQuery";

export const groupsAssignmentApi = createApi({
  reducerPath: "groupsAssignmentApi",
  baseQuery: RTKbaseQuery,
  tagTypes: ["GroupsAssignment"],
  endpoints: (builder) => ({
    assignGroupsToPool: builder.mutation({
      query: ({
        organizationId,
        tenantId,
        resourcePoolId,
        groupIds,
        action,
      }) => ({
        url: `/organizations/${organizationId}/tenants/${tenantId}/resourcePools/${resourcePoolId}/accessControl/groups`,
        method: "PUT",
        body: { groupIds, action },
      }),
      invalidatesTags: ["GroupsAssignment"],
    }),
    getPoolMembers: builder.query({
      query: ({ organizationId, tenantId, resourcePoolId, memberType }) => ({
        url:
          `/organizations/${organizationId}/tenants/${tenantId}/resourcePools/${resourcePoolId}/members` +
          `${memberType ? `?memberType=${memberType}` : ""}`,
        method: "GET",
      }),
      providesTags: ["GroupsAssignment"],
    }),
  }),
});

export const { useAssignGroupsToPoolMutation, useGetPoolMembersQuery } =
  groupsAssignmentApi;
