import { Tooltip } from "@veneer/core";
import React from "react";
import { BadgeStyled } from "./styles";

export const GradientBadge = (props) => {
  const { label, tooltipText } = props;
  return (
    <>
      {tooltipText ? (
        <Tooltip
          placement="top-start"
          content={<span>{tooltipText}</span>}
          id="tooltip"
        >
          <BadgeStyled>{label}</BadgeStyled>
        </Tooltip>
      ) : (
        <BadgeStyled>{label}</BadgeStyled>
      )}
    </>
  );
};
