import { styled } from "@mui/material";
import { Button } from "@veneer/core";
import React from "react";

const commonStyles = (theme) => ({
  "&:disabled": {
    pointerEvents: "auto",
    backgroundColor: theme.palette.background.disabled,
    color: theme.palette.text.disabled,
    border: "unset",
  },
  "&:hover:disabled": {
    backgroundColor: theme.palette.background.disabled,
    color: theme.palette.text.disabled,
    border: "unset",
  },
  "&:active:disabled": {
    backgroundColor: theme.palette.background.disabled,
    color: theme.palette.text.disabled,
    border: "unset",
  },
});

const IconButton = styled(Button)(({ theme }) => ({
  minWidth: "unset",
  padding: "unset",
  border: "unset",
  backgroundColor: "unset",
  "&:hover": {
    backgroundColor: "unset",
  },
  ...commonStyles(theme),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "360px",
  ...commonStyles(theme),
}));

const VeneerButton = React.forwardRef(({ iconButton, ...other }, ref) => {
  const ButtonToRender = iconButton ? IconButton : StyledButton;

  return <ButtonToRender small ref={ref} {...other} />;
});

VeneerButton.displayName = "VeneerButton";

export default VeneerButton;
